import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { useIntl } from "gatsby-plugin-intl";
import { Title, Section, Text } from "../../../components/Core";
import PricingCardWrapper from "../../../components/Flop/PricingCard";
import Divider from "../../../components/Flop/Divider";
import FlopFormattedMessageWrapper from "../../../components/Flop/FlopFormattedMessageWrapper";

const AdPlans = () => {
  const intl = useIntl();
  return (
    <>
      <Section bg="greyBg">
        <Container>
          <Row className="align-items-center justify-content-center mb-5">
            <Col lg="6" className="text-center">
              <Title className="mb-5">
                {intl.formatMessage({
                  id: "products.flopAd.plans.title",
                })}
              </Title>
              <Text variant="small" opacity={0.7}>
                <FlopFormattedMessageWrapper id="products.flopAd.plans.subtitle" />
              </Text>
            </Col>
          </Row>

          <Row className="align-items-center justify-content-center">
            <Col lg="4" md="6" className="text-center">
              <PricingCardWrapper
                title={intl.formatMessage({
                  id: "products.flopAd.plans.inactiveReach.title",
                })}
                fixedPrice={intl.formatMessage({
                  id: "products.flopAd.plans.inactiveReach.fixedPrice",
                })}
                planLink=""
                displayButton={false}
              />
            </Col>
            <Col lg="4" md="6" className="text-center">
              <PricingCardWrapper
                title={intl.formatMessage({
                  id: "products.flopAd.plans.reach.title",
                })}
                fixedPrice={intl.formatMessage({
                  id: "products.flopAd.plans.reach.fixedPrice",
                })}
                planLink=""
                displayButton={false}
              />
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center my-5">
            <Col md="5" className="text-center">
              <Divider backgroundColor="#B3C0CD" />
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col md="6" className="text-center">
              <Text variant="small" opacity={0.7}>
                <FlopFormattedMessageWrapper id="products.flopAd.plans.reach.desc" />
              </Text>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center my-5">
            <Col md="5" className="text-center">
              <Divider backgroundColor="#B3C0CD" />
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center mb-5">
            <Col md="6" className="text-center">
              <Text variant="small" opacity={0.7}>
                <FlopFormattedMessageWrapper id="products.flopAd.plans.notif.desc" />
              </Text>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col lg="4" md="6" className="text-center">
              <PricingCardWrapper
                title={intl.formatMessage({
                  id: "products.flopAd.plans.notif.title",
                })}
                fixedPrice={intl.formatMessage({
                  id: "products.flopAd.plans.notif.fixedPrice",
                })}
                planLink=""
                displayButton={false}
              />
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default AdPlans;
