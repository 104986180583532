import React from "react";
import styled from "styled-components";
import { Box, Button } from "../Core";

const PricingCard = styled.div`
  border-radius: 10px;
  border: 1px solid #eae9f2;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15), 0px 4px 5px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  text-align: center;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
  padding-top: 32px;
  .small-title {
    color: #696871;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: -0.56px;
    line-height: 28px;
    margin-bottom: 22px;
  }
  .pag-pricing {
    color: #323f48;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: -0.56px;
    line-height: 28px;
    margin-bottom: 22px;
  }
  .title {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: -1.03px;
    line-height: 56px;
    color: #1d293f;
    margin-bottom: 30px;
  }
  .time {
    font-size: 28px;
    font-weight: 300;
    letter-spacing: -1.03px;
    line-height: 1;
  }
`;

const ULStyled = styled.ul`
  color: #696871;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 1.6;
  list-style: none;
  padding: 0;
  li {
    margin-bottom: 14px;
  }
`;

// This pricing card wrapper was created with PricingTable1.js and its components.
const PricingCardWrapper = ({
  title,
  fixedPrice,
  payAsYouGoPrice,
  planLink,
  displayButton = true,
  buttonOutlineColor,
  children,
}) => {
  return (
    <PricingCard className="mb-4">
      <span className="small-title">{title}</span>
      <div className="mb-3">
        <h2 className="title mt-2 mb-1">{fixedPrice}</h2>
        {payAsYouGoPrice && (
          <span className="pag-pricing">{payAsYouGoPrice}</span>
        )}
      </div>
      <ULStyled>{children}</ULStyled>
      {/** Display button only if its requested */}
      {displayButton && (
        <Box className="mt-4">
          <a href={planLink} target="_blank" rel="noreferrer">
            <Button
              mb={3}
              color={buttonOutlineColor}
              bg="light"
              variant="outline"
            >
              Get Started
            </Button>
          </a>
        </Box>
      )}
    </PricingCard>
  );
};
export default PricingCardWrapper;
