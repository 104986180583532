import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Button, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";

const SectionStyled = styled(Section)`
  background: radial-gradient(
    60.03% 100% at 50.63% 0%,
    #50798b 0%,
    #0f1c29 100%
  );
`;

const ImgRight = styled.img`
  max-width: 50%;
  @media ${device.sm} {
    max-width: 60%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
`;

const HeroProduct = ({
  title,
  button,
  buttonColor,
  image,
  logo,
  productLink,
  optionalInfo,
  children,
  ...rest
}) => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <SectionStyled className="position-relative">
        <div className="pt-5"></div>
        <Container>
          <Row className="justify-content-center align-items-center mb-5">
            <img src={logo} alt="flop whole logo"></img>
          </Row>
          <Row className="justify-content-center align-items-center">
            <Col lg="6" md="8" sm="9" className="order-lg-2">
              <div className="text-center text-lg-right position-relative">
                <div
                  className="img-main"
                  data-aos="fade-down"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="500"
                >
                  <ImgRight src={image} alt=" flop product" />
                </div>
              </div>
            </Col>
            <Col lg="6" className="order-lg-1">
              <div
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-once="true"
                data-aos-delay="500"
              >
                <Box py={[null, null, null, 5]} pr={5} pt={[4, null]}>
                  <Title color="light">{title}</Title>
                  <Text color="light" mb={4}>
                    {children}
                  </Text>
                  <div className="d-flex flex-column align-items-start">
                    <a href={productLink} target="_blank" rel="noreferrer">
                      <Button mb={3} bg={buttonColor}>
                        {button}
                      </Button>
                    </a>
                    {optionalInfo}
                  </div>
                </Box>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default HeroProduct;
