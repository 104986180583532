import React from "react";

import HeroProduct from "../sections/flop-products/HeroProduct";
import AdPresentation from "../sections/flop-products/flop-ad/AdPresentation";
import AdPlans from "../sections/flop-products/flop-ad/AdPlans";
import PageWrapper from "../components/PageWrapper";
import FlopFormattedMessageWrapper from "../components/Flop/FlopFormattedMessageWrapper";

import flopAdPresentation from "../assets/image/flop/products/flop-ad/flop-ad.png";
import flopAdLogo from "../assets/image/flop/products/flop-ad/flop-ad-logo.png";
import Seo from "../components/Seo/seo";

const FlopAdPage = () => {
  return (
    <PageWrapper headerDark>
      <Seo title="Flop Ad" desc="Improve your poker room attendency." />
      <HeroProduct
        title={<FlopFormattedMessageWrapper id="products.flopAd.hero.title" />}
        button={<FlopFormattedMessageWrapper id="products.flopAd.hero.start" />}
        buttonColor="purple"
        image={flopAdPresentation}
        logo={flopAdLogo}
        productLink=""
      >
        <FlopFormattedMessageWrapper id="products.flopAd.hero.subtitle" />
      </HeroProduct>
      <AdPresentation />
      <AdPlans />
    </PageWrapper>
  );
};
export default FlopAdPage;
