import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Text } from "../../../components/Core";
import IconCard from "../../../components/Flop/IconCard";
import FlopFormattedMessageWrapper from "../../../components/Flop/FlopFormattedMessageWrapper";
import badgeCall from "../../../assets/image/flop/products/flop-ad/badge-call.png";
import badgePerformance from "../../../assets/image/flop/products/flop-ad/badge-performance.png";
import badgeSpread from "../../../assets/image/flop/products/flop-ad/badge-spread.png";

const AdPresentation = () => {
  return (
    <>
      <Section>
        <Container>
          <Row className="align-items-center justify-content-center mb-5">
            <Col lg="6" className="text-center">
              <Title className="mb-5">
                <FlopFormattedMessageWrapper id="products.flopAd.presentation.title" />
              </Title>
              <Text variant="small" opacity={0.7}>
                <FlopFormattedMessageWrapper id="products.flopAd.presentation.subtitle" />
              </Text>
            </Col>
          </Row>

          <Row className="align-items-center justify-content-center">
            <Col lg="4" md="6" className="text-center mb-4">
              <IconCard
                image={badgeCall}
                desc={
                  <FlopFormattedMessageWrapper id="products.flopAd.presentation.badge.call" />
                }
              />
            </Col>
            <Col lg="4" md="6" className="text-center mb-4">
              <IconCard
                image={badgePerformance}
                desc={
                  <FlopFormattedMessageWrapper id="products.flopAd.presentation.badge.performance" />
                }
              />
            </Col>
            <Col lg="4" md="6" className="text-center mb-4">
              <IconCard
                image={badgeSpread}
                desc={
                  <FlopFormattedMessageWrapper id="products.flopAd.presentation.badge.spread" />
                }
              />
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default AdPresentation;
